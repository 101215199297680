<template>
  <div class="block-space">
    <div class="container">
      <div class="no-gutters row row-grid align-items-center">
        <div class="col-md-6 order-md-1 px-4 px-sm-auto">
          <div class="pr-lg-5">
            <h1 class="h3">{{ title }}</h1>
            <p>
              {{ desc }}
            </p>
          </div>
        </div>
        <div class="col-md-6 order-md-2 px-4 px-sm-auto d-flex justify-content-center">
          <iframe
            class="w-100"
            width="560"
            height="315"
            :src="src"
            title="Meet the FIAF"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['title', 'desc', 'src'],
  }
</script>
