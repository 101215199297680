<template>
  <div>
    <feature-section
      :title="$t('Views.FrontHome.featureSectionTitle')"
      :desc="$t('Views.FrontHome.featureSectionDesc')"
      :src="'https://www.youtube.com/embed/5zKRXVZMDPk?enablejsapi=1'"
    />

    <statistics />

    <flags-carousel />

    <testimonial
      :data="testimonials"
      @setSelectedTestimonial="handleSelectedTestimonial($event)"
    />

    <home-video-carousel
      :title="$t('Views.FrontHome.videoCarouselTitle')"
      :desc="$t('Views.FrontHome.videoCarouselDescription')"
      :videos="videoCarousel"
    ></home-video-carousel>

    <news
      :title="$t('Views.FrontHome.newsTitle')"
      :button="$t('Views.FrontHome.newsButtonText')"
      @updateSelectedPublication="handleSelectedPublication($event)"
    />

    <div class="bg-secondary">
      <events
        :limitOfItems="4"
        @updateSelectedPublication="handleSelectedPublication($event)"
      ></events>
      <v-row
        class="w-100 bg-secondary position-relative bottom-4 m-0 d-flex justify-content-center align-content-center"
      >
        <router-link to="/news#events"
          ><v-btn outlined color="#001c6c">
            {{ $t('Views.FrontHome.eventsButtonText') }}
          </v-btn></router-link
        >
      </v-row>
    </div>

    <!-- Modal for the contend of publications -->
    <modal
      id="content-modal"
      name="contentModal"
      height="85%"
      width="80%"
      :adaptive="true"
      :focusTrap="true"
      @before-open="beforeOpenModal"
      @before-close="beforeCloseModal"
    >
      <v-row no-gutters class="post-container h-100 p-4">
        <v-btn
          @click="
            beforeCloseModal
            $modal.hide('contentModal')
          "
          :icon="true"
          small
          absolute
          top
          right
          style="z-index: 9"
          outlined
          alt="Read more"
          color="#001c6c"
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <!-- <h1 class="text-center w-100 h3 m-3">{{ publication.title }}</h1> -->
        <!-- <v-row no-gutters class="w-100"> </v-row> -->
        <div class="col-11 col-sm-5 mx-auto mx-sm-0 p-3">
          <!-- Img -->
          <div style="display: flex; justify-content: center; align-items: center">
            <v-img
              class="post-img img-fluid"
              style="width: 100%"
              :alt="publication.title"
              v-if="publication.cover && publication.cover.route"
              :src="publication.cover.route"
            />
          </div>
        </div>

        <!-- CONTENT -->
        <div class="post-content h-100 col-12 col-sm-7 p-3">
          <h1 class="text-center h3 mx-5">{{ publication.title }}</h1>
          <countries-flags
            :countries="
              publication.countries && publication.countries.length > 0
                ? publication.countries
                : []
            "
          ></countries-flags>
          <ul class="post-meta my-2" v-if="publication.type !== 'events'">
            <li v-if="publication.date">
              {{
                new Date(publication.date).toLocaleDateString('es-VE', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })
              }}
            </li>
            <li>
              {{ publication.author }}
            </li>
          </ul>
          <p v-if="publication.type === 'events'">
            <span v-if="publication.address"
              ><strong>Ubicación:</strong> {{ publication.address }}</span
            ><br v-if="publication.address" />
            <span v-if="publication.dateRange > 0 && publication.startAt"
              ><strong>Inicia:</strong> {{ publication.startAt }}</span
            ><br v-if="publication.dateRange > 0 && publication.startAt" />
            <span v-if="publication.dateRange > 0 && publication.expired_at"
              ><strong>Culmina:</strong> {{ publication.endAt }}</span
            ><br v-if="publication.dateRange > 0 && publication.expired_at" />
            <span v-if="publication.dateRange == 0"
              ><strong>Fecha:</strong> {{ publication.startAt + ' . Único día' }}</span
            ><br v-if="publication.dateRange == 0" />
            <span v-if="publication.modality"
              ><strong>Modalidad:</strong> {{ publication.modalityText }}</span
            >
          </p>

          <div class="post-text text-box" v-html="publication.content"></div>
        </div>
      </v-row>
    </modal>
    <modal
      name="testimonialModal"
      :width="$vuetify.breakpoint.sm ? '90%' : '60%'"
      height="80%"
    >
      <v-row class="post-container2 p-2 p-md-4 mx-0" v-if="testimonialDialog.image">
        <v-col cols="12" sm="5">
          <div class="d-flex justify-center">
            <v-img
              class="post-img img-fluid"
              style="width: 100%"
              contain
              alt=""
              :src="testimonialDialog.image"
            />
          </div>
        </v-col>
        <!-- <div class="col-12 col-sm-5 mx-auto p-3">

        </div> -->
        <!-- CONTENT -->
        <v-col cols="12" sm="7" class="post-content h-100"
          ><v-btn
            @click="hideModal()"
            :icon="true"
            small
            absolute
            top
            right
            style="z-index: 9"
            outlined
            alt="Read more"
            color="#001c6c"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <h1 class="text-center h4 mx-2 mx-md-4">{{ testimonialDialog.name }}</h1>
          <p class="m-0">{{ testimonialDialog.position }}</p>
          <p>{{ testimonialDialog.association }}</p>
          <div class="post-text text-box" v-html="testimonialDialog.text"></div
        ></v-col>
        <!-- <div class="col-12 col-sm-7 p-2 p-md-4">

        </div> -->
      </v-row>
    </modal>
  </div>
</template>

<script>
  import FeatureSection from 'Components/FrontendComponents/FeatureSection'
  import Testimonial from 'Components/FrontendComponents/Testimonial'
  import FlagsCarousel from 'Components/FrontendComponents/FlagsCarousel'
  import CountriesFlags from 'Components/FrontendComponents/CountriesFlags.vue'
  import Events from 'Components/FrontendComponents/Events'
  import Statistics from 'Components/FrontendComponents/HomeStatistics'
  import News from 'Components/FrontendComponents/HomeNews'
  import MyData2 from 'Data/dashboardtwo.json'
  import HomeVideoCarousel from 'Components/FrontendComponents/HomeVideoCarousel.vue'
  export default {
    name: 'Home',
    data() {
      return {
        testimonials: MyData2.data.testimonials,
        publication: [],
        testimonialDialog: {},
        testiDialog: false,
        videos: [
          {
            src: 'https://www.youtube.com/embed/QZjyUXPP7t0',
            desc:
              'Intervención de José Roberto Fernández, Director General FIAF desde Guatemala en el Conversatorio Legal.',
          },
          {
            src: 'https://www.youtube.com/embed/Rtbv_-Hsd4M',
            desc:
              'Intervención de Luis Felipe Jaramillo, Vicepresidente de Colfranquicias desde Colombia en el Conversatorio Legal.',
          },
          {
            src: 'https://www.youtube.com/embed/0b3obwUl99Y',
            desc:
              'Intervención de Natan Baril, Director Internacional de la ABF desde Brasil en el Conversatorio Legal.',
          },
          {
            src: 'https://www.youtube.com/embed/Nb09g-_RI5Q',
            desc:
              ' Intervención de José Cavaleiro Machado, Director Legal FIAF desde Portugal en el Conversatorio Legal.',
          },
          {
            src: 'https://www.youtube.com/embed/QZjyUXPP7t0',
            desc:
              'Conversatorio Legal: Regulaciones y Marco Legal de las Franquicias en Iberoamérica.',
          },
          {
            src: 'https://www.youtube.com/embed/7DDh908-pFE',
            desc:
              'Webinar: Las Franquicias como Factor de reactivación económica en Iberoamérica.',
          },
        ],
      }
    },
    components: {
      FlagsCarousel,
      Events,
      FeatureSection,
      Testimonial,
      Statistics,
      News,
      CountriesFlags,
      HomeVideoCarousel,
    },
    computed: {
      videoCarousel() {
        return this.videos
      },
    },
    methods: {
      handleSelectedTestimonial(testimonial) {
        console.log(testimonial)
        this.testimonialDialog = testimonial
        this.$modal.show('testimonialModal')
      },
      hideModal() {
        this.$modal.hide('testimonialModal')
      },
      handleSelectedPublication(publication) {
        this.$modal.show('contentModal')
        this.publication = publication
      },
      beforeOpenModal() {
        // When the modal is shown, we want a fixed body
        const topBody = window.scrollY
        document.body.style.position = 'fixed'
        document.body.style.top = `-${topBody}px`
      },
      beforeCloseModal() {
        // When the modal is hidden...
        const scrollY = document.body.style.top
        document.body.style.position = ''
        document.body.style.top = ''
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      },
    },
  }
</script>
<style lang="scss">
  .post-container {
    overflow: auto;
    height: calc(100% - 200px);
  }
  .pointer {
    cursor: pointer;
  }
  .post-container2 {
    background: #fff;
    overflow: auto;
    // height: calc(100% - 200px);
  }
  .post-img {
    background-position-y: top;
  }
  .vm--container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .vm--modal {
    height: auto !important;
    max-height: 70% !important;
    overflow-x: hidden;
    overflow-y: scroll;
    top: auto !important;
    left: auto !important;
  }
</style>
