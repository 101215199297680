<template>
  <div id="events" data-app class="block-space bg-secondary">
    <div class="block-head text-center mb-0">
      <h1 class="h3 m-0">
        {{
          $route.path === '/'
            ? $t('Components.FrontEvents.title1')
            : $t('Components.FrontEvents.title2')
        }}
      </h1>
      <p class="text-primary">{{ $t('Components.FrontEvents.desc') }}</p>
      <v-btn
        v-if="$route.name === 'Publications'"
        class="mr-2"
        outlined
        text
        @click.prevent="
          future = true
          getEvents()
        "
        >{{ $t('Components.FrontEvents.button1') }}</v-btn
      >
      <v-btn
        v-if="$route.name === 'Publications'"
        outlined
        text
        @click.prevent="
          future = false
          getEvents()
        "
        >{{ $t('Components.FrontEvents.button2') }}</v-btn
      >
    </div>
    <div class="container" style="">
      <swiper class="pb-4" :options="swiperOption">
        <swiper-slide
          class="p-2 my-2 card card-lift--hover pointer shadow"
          v-for="(item, index) in events"
          :key="index"
        >
          <div @click="$emit('updateSelectedPublication', item)" class="h-100">
            <v-img :aspect-ratio="1" contain :src="item.cover.route" alt=""></v-img>
            <v-card-text class="p-2">
              <h5 class="h6">{{ item.title }}</h5>
              <p class="m-0" style="height: 90px">
                {{ item.shortdesc }}
              </p>
              <v-divider dark class="mx-4 my-1"></v-divider>
              <v-icon v-if="item.countries.length == 0" style="font-size: xx-large">
                mdi-web
              </v-icon>
              <img v-else class="country__flag" :src="item.countries[0].flag" />
              <v-card-text style="height: 66px" class="my-2 p-0">
                <span v-if="item.dateRange > 0 && item.startAt"
                  ><strong>Inicia:</strong> {{ item.startAt }}</span
                ><br v-if="item.dateRange > 0 && item.startAt" />
                <span v-if="item.dateRange > 0 && item.expired_at"
                  ><strong>Culmina:</strong> {{ item.endAt }}</span
                ><br v-if="item.dateRange > 0 && item.expired_at" />
                <span v-if="item.dateRange == 0"
                  ><strong>Fecha:</strong> {{ item.startAt + ' . Único día' }}</span
                ><br v-if="item.dateRange == 0" />
                <span v-if="item.modality"
                  ><strong>Modalidad:</strong> {{ item.modalityText }}</span
                >
              </v-card-text>
              <v-row class="justify-content-end p-2">
                <button-show-more @click="$emit('updateSelectedPublication', item)" />
              </v-row>
            </v-card-text>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
  import ButtonShowMore from './ButtonShowMore.vue'
  import CountriesFlags from '@/components/FrontendComponents/CountriesFlags.vue'

  export default {
    name: 'Events',
    components: { ButtonShowMore, CountriesFlags },
    props: { limitOfItems: { type: Number } },
    data() {
      return {
        future: true,
        events: [],
        swiperOption: {
          centerInsufficientSlides: true,
          speed: 300,
          autoplay: {
            delay: 10000,
            // waitForTransition:false,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 1,
            type: 'bullets',
          },
          setWrapperSize: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          breakpoints: {
            1440: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1025: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 20,
            },

            320: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
          },
        },
      }
    },
    created() {
      this.getEvents()
    },
    methods: {
      async getEvents() {
        this.loadingEvents = true
        let orderByString = ''
        let futureSwitch = ''
        this.future ? (futureSwitch = '&future=1') : (futureSwitch = '&last=1')
        this.future ? (orderByString = '&by=asc') : (orderByString = '&by=desc')
        axios
          .get(
            'publications?order=date&type=events&active=1&limit=' +
              this.limitOfItems +
              orderByString +
              futureSwitch
          )
          .then((response) => {
            this.events = response.data
            this.events.map((event) => {
              event.startAt = new Date(event.date).toLocaleDateString('es-VE', {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
              event.endAt = new Date(event.expired_at).toLocaleDateString('es-VE', {
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
              event.dateRange = Math.ceil(
                Math.abs(new Date(event.date) - new Date(event.expired_at)) /
                  (1000 * 60 * 60 * 24)
              )
              switch (event.modality) {
                case 'site':
                  event.modalityText = 'Presencial'
                  break
                case 'remote':
                  event.modalityText = 'Virtual'
                  break
                case 'remote-site':
                  event.modalityText = 'Virtual - Presencial'
                  break
                default:
                  event.modalityText = 'N/A'
                  break
              }
            })
            this.loadingEvents = false
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .country__flag {
    margin: 5px 0;
    width: 35px;
    height: 25px;
  }
  h5,
  p {
    line-height: 1.3;
  }
  h5 {
    min-height: 70px;
  }
  .swiper-container {
    padding-bottom: 20px;
  }
</style>
