<template>
  <div class="block-space bg-secondary">
    <div class="container">
      <div class="row row-grid align-items-center">
        <div class="col-md-6 order-md-2 px-4 px-sm-auto">
          <v-row class="">
            <div class="col d-flex justify-content-center align-items-center flex-column">
              <h1 class="m-0" style="color: primary">13</h1>
              <p class="text-center m-0">
                {{ $t('Components.FrontHomeStatistics.countryDesc') }}
              </p>
            </div>
            <div class="col d-flex justify-content-center align-items-center flex-column">
              <h1 class="m-0" style="color: primary">+7000</h1>
              <p class="text-center m-0">
                {{ $t('Components.FrontHomeStatistics.brandDesc') }}
              </p>
            </div>
          </v-row>
          <v-row class="">
            <div class="col d-flex justify-content-center align-items-center flex-column">
              <h1 class="m-0" style="color: primary">10%</h1>
              <p class="text-center m-0">
                {{ $t('Components.FrontHomeStatistics.growthDesc') }}
              </p>
            </div>

            <div class="col d-flex justify-content-center align-items-center flex-column">
              <h1 class="m-0" style="color: primary">25</h1>
              <p class="text-center m-0">
                {{ $t('Components.FrontHomeStatistics.yearsDesc') }}
              </p>
            </div>
          </v-row>
        </div>
        <div class="col-md-6 order-md-1 m-0 px-4 px-sm-auto">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide class="p-4" v-for="(image, index) in images" :key="index">
              <v-img cover :src="image.src" />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
          <!-- <div class="card shadow border-0">
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Statistics',
    created() {},
    data() {
      return {
        images: [
          {
            src: '/static/images/statistics/1.png',
          },
          {
            src: '/static/images/statistics/2.png',
          },
          {
            src: '/static/images/statistics/3.png',
          },
          {
            src: '/static/images/statistics/4.png',
          },
          {
            src: '/static/images/statistics/5.png',
          },
          {
            src: '/static/images/statistics/6.png',
          },
          {
            src: '/static/images/statistics/1.1.png',
          },
          {
            src: '/static/images/statistics/2.1.png',
          },
          {
            src: '/static/images/statistics/3.1.png',
          },
        ],
        swiperOption: {
          speed: 300,
          autoplay: {
            delay: 6000,
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            dynamicBullets: true,
          },
          setWrapperSize: true,
        },
      }
    },
    props: {},
    methods: {},
  }
</script>

<style lang="scss" scoped></style>
