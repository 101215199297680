<template>
	<div class="block-space testimonial-main" data-app>
		<!-- Info Section -->
		<div class="block-head text-center">
			<h1 class="head-line h3">{{ $t('Components.FrontTestimonial.title') }}</h1>
			<div class="col-12 col-md-7 mx-auto">
				<p>{{ $t('Components.FrontTestimonial.desc') }}</p>
			</div>
		</div>
		<!-- Info Section / End -->
		<!-- Categories Carousel -->
		<div class="fullwidth-carousel-container margin-top-20">
			<slick
				:options="slickOptions"
				v-if="data"
				class="testimonial-carousel testimonials"
			>
				<template v-for="testimonial in data">
					<div class="fw-carousel-review" :key="testimonial.name">
						<div class="testimonial-box mx-sm-5 mx-md-auto px-3 py-4 mt-0">
							<div
								:id="testimonial.id"
								class="testimonial"
								v-html="testimonial.shortText"
							></div>
							<v-btn
								icon
								color="#fff"
								absolute
								bottom
								right
								@click="handleSelectedTestimonial(testimonial)"
								><v-icon>mdi-plus-circle</v-icon></v-btn
							>
						</div>
						<div class="testimonial-author py-0">
							<img :src="testimonial.imageRounded" alt="image" />
							<h4>
								{{ testimonial.name }}
								<v-img
									class="mx-auto"
									style="border-radius: 50%"
									width="50"
									:src="testimonial.flag"
								></v-img>
							</h4>
						</div>
					</div>
				</template>
			</slick>
		</div>
	</div>
</template>
<script>
	import Slick from 'vue-slick'
	import ButtonShowMore from './ButtonShowMore.vue'
	export default {
		props: ['title', 'desc', 'data'],
		data() {
			return {
				testimonialDialog: {},
				testiDialog: false,
			}
		},
		components: {
			Slick,
			ButtonShowMore,
		},
		computed: {
			slickOptions() {
				return {
					centerMode: true,
					centerPadding: '0px',
					slidesToShow: 3,
					slidesToScrol: 1,
					dots: true,
					arrows: false,
					autoplay: {
						delay: 5000,
					},
					infinite: true,
					autoplaySpeed: 6000,
					responsive: [
						{
							breakpoint: 1100,
							settings: {
								centerMode: true,
								centerPadding: 0,
								slidesToShow: 2,
							},
						},
						{
							breakpoint: 767,
							settings: {
								centerMode: true,
								centerPadding: 0,
								slidesToShow: 1,
							},
						},
					],
				}
			},
		},
		methods: {
			handleSelectedTestimonial(testimonial) {
				this.$emit('setSelectedTestimonial', testimonial)
			},
		},
	}
</script>
<style lang="scss">
	@import '@/assets/scss/third-party/slick-slider.scss';
	.testimonial-main {
		.testimonial:after {
			position: absolute;
			top: 5px;
			left: 10px;
			content: '';
			font-family: 'FontAwesome';
			font-size: 20px;
			opacity: 0.3;
			color: #fff !important;
			background-color: transparent !important;
			cursor: default;
		}
		.testimonial-carousel .slick-slide {
			margin: 0 20px;
		}
	}

	.pointer {
		cursor: pointer;
	}
	.testimonial {
		height: 200px;
		// max-height: 350px;

		p {
			width: 100%;
			height: 200px;
			// max-height: 330px;
		}
	}
</style>
