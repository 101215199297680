<template>
  <div data-app>
    <v-row no-gutters class="" v-if="countries && countries.length > 0">
      <picture v-for="(country, id) in countries" :key="id">
        <img
          class="country__flag"
          style="height: 30px; width: 40px"
          :title="country.name"
          :src="country.flag"
        />
      </picture>
    </v-row>
    <v-row no-gutters class="" v-else-if="countries && countries.length == 0">
      <v-icon style="font-size: xx-large; height: 30px" title="Global"> mdi-web </v-icon>
    </v-row>
  </div>
</template>
<script>
  export default {
    name: 'CountriesFlags',
    props: {
      countries: {
        default: [],
        type: Array,
        required: true,
      },
    },
  }
</script>
<style lang="scss">
  .country__flag {
    margin: 0 5px;
    width: 35px;
    height: 25px;
  }
</style>
