<template>
	<div class="block-space mx-md-5">
		<div class="mx-4 mx-md-auto container-lg">
			<h1 class="head-line h3 w-100">{{ title }}</h1>
			<p class="text-primary">{{ desc }}</p>
			<swiper class="mt-4 home-video-swiper" :options="swiperOption">
				<swiper-slide
					class="iframe-container"
					v-for="(item, index) in videos"
					:index="index"
					:key="index"
				>
					<iframe
						class="videoIframe px-2 px-md-5 px-lg-4 w-100"
						:src="item.src"
						frameborder="0"
						allow="autoplay; fullscreen; picture-in-picture"
					></iframe>
					<p class="my-2">{{ item.desc }}</p>
				</swiper-slide>
				<div class="swiper-button-prev mr-4" slot="button-prev"></div>
				<div class="swiper-button-next ml-4" slot="button-next"></div>
			</swiper>

			<v-row no-gutters class="justify-content-end">
				<v-btn text
					><a
						href="https://www.youtube.com/channel/UCBot1AoFwAhGUcM-alcfNWQ"
						target="_blank"
					>
						Ver más
					</a></v-btn
				>
			</v-row>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'HomeVideoCarousel',
		props: {
			title: String,
			desc: String,
			videos: Array,
		},
		data() {
			return {
				swiperOption: {
					centerInsufficientSlides: true,
					setWrapperSize: true,
					slidesToShow: 2,
					spaceBetween: 20,
					speed: 1500,
					autoplay: {
						delay: 10000,
					},
					infinite: true,
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
					pagination: false,
					centerInsufficientSlides: true,
					breakpoints: {
						991: {
							slidesPerView: 2,
						},

						320: {
							slidesPerView: 1,
						},
					},
				},
			}
		},
	}
</script>
<style lang="scss">
	.home-video-swiper {
		.iframe-container {
			padding: 0 1.8rem;
			/* flex-direction: column; */
		}
		.videoIframe {
			aspect-ratio: 16/9;
		}

		.swiper-button-prev,
		.swiper-container-rtl .swiper-button-next {
			left: 0px;
			right: auto;
		}
		.swiper-button-next,
		.swiper-container-rtl .swiper-button-prev {
			right: 0px;
			left: auto;
		}
		.swiper-button-prev:after,
		.swiper-button-next:after {
			font-size: 1.5rem;
		}
	}
</style>
