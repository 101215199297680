<template>
  <div class="block-space bg-secondary">
    <div class="mx-md-5">
      <div class="block-head text-center mb-2">
        <h1 class="head-line h3">{{ title }}</h1>
      </div>
      <v-row class="mx-4 mt-4 mx-md-auto container-lg" v-if="!loading">
        <v-row no-gutters>
          <!-- PRINCIPAL NEW (BIG ONE) -->
          <div
            class="col-12 col-sm-10 col-md-8 col-lg-6 p-3 mx-auto principal-new-container pointer border-red"
            v-if="principalNew != []"
            @click="$emit('updateSelectedPublication', principalNew)"
          >
            <v-img
              v-if="principalNew && principalNew.cover.route != ''"
              :aspect-ratio="1"
              cover
              class="card-img floating principal-new-img"
              :src="principalNew.cover.route"
              alt=""
            ></v-img>
            <v-row
              class="position-absolute top-0 start-0 end-0 bottom-0 bottom-0 h-100 w-100 flex-column align-items-center justify-content-center text-white principal-new-content px-3 px-sm-5"
              style="width: calc(100% - 1.5rem)"
            >
              <h2 class="text-white h3 display-sm-4 text-center fs">
                {{ principalNew.title }}
              </h2>
              <p class="text-center py-3 d-none d-sm-block">
                {{ principalNew.shortdesc }}
              </p>
            </v-row>
          </div>
          <!-- VERTICAL NEWS -->
          <div
            class="col-12 col-lg-6 px-2 px-sm-5 px-lg-3 d-flex flex-column"
            v-if="news"
          >
            <v-row
              no-gutters
              class="items__container shadow mb-3 mx-2 mx-sm-5 mx-lg-0 pointer"
              v-for="(item, id) in news.slice(1, 3)"
              @click="$emit('updateSelectedPublication', item)"
              :key="id"
            >
              <div class="col-10 col-sm-4 mx-auto p-0">
                <v-img
                  v-if="item && item.cover.route != '' && $vuetify.breakpoint.lgAndUp"
                  :aspect-ratio="1 / 1.5"
                  contain
                  :src="item.cover.route"
                  alt=""
                ></v-img>
                <v-img
                  v-if="item && item.cover.route != '' && $vuetify.breakpoint.mdAndDown"
                  :aspect-ratio="1 / 1.5"
                  contain
                  :src="item.cover.route"
                  alt=""
                ></v-img>
              </div>

              <div class="col-12 col-sm-8 p-3">
                <div class="h-100 d-flex flex-column">
                  <h5>
                    {{ item.title }}
                  </h5>
                  <p>
                    {{ item.shortdesc }}
                  </p>
                  <v-row
                    no-gutters
                    class="justify-content-end"
                    style="
                      align-content: flex-end;
                      height: fit-content;
                      justify-self: flex-end;
                    "
                  >
                    <button-show-more @click="$emit('updateSelectedPublication', item)" />
                  </v-row>
                </div>
              </div>
            </v-row>
          </div>
          <!-- HORIZONTAL NEWS -->
          <v-row no-gutters class="mx-2 mx-sm-5 mx-lg-auto">
            <div
              class="col-12 col-lg-6 px-lg-3 mb-4 pointer"
              v-for="(item, id) in news.slice(3, 5)"
              :key="id"
              @click="$emit('updateSelectedPublication', item)"
            >
              <v-row no-gutters class="items__container mx-2 mx-sm-5 mx-lg-auto">
                <div class="col-8 col-sm-4 mx-auto p-0">
                  <v-img
                    v-if="item && item.cover.route != '' && $vuetify.breakpoint.lgAndUp"
                    :aspect-ratio="1 / 1.5"
                    contain
                    :src="item.cover.route"
                    alt=""
                  ></v-img>
                  <v-img
                    v-if="item && item.cover.route != '' && $vuetify.breakpoint.mdAndDown"
                    :aspect-ratio="1 / 1.5"
                    contain
                    :src="item.cover.route"
                    alt=""
                  ></v-img>
                </div>
                <div class="col-12 col-sm-8">
                  <div class="p-3 h-100 d-flex flex-column">
                    <h5>{{ item.title }}</h5>
                    <p>{{ item.shortdesc }}</p>
                    <v-row
                      no-gutters
                      class="justify-content-end"
                      style="
                        align-content: flex-end;
                        height: fit-content;
                        justify-self: flex-end;
                      "
                    >
                      <button-show-more
                        @click="$emit('updateSelectedPublication', item)"
                      />
                    </v-row>
                  </div>
                </div>
              </v-row>
            </div>
          </v-row>
        </v-row>
      </v-row>
      <v-row class="justify-content-center mt-4">
        <router-link to="/news/#news"
          ><v-btn outlined color="#001c6c"> {{ button }} </v-btn></router-link
        >
      </v-row>
    </div>
  </div>
</template>

<script>
  import ButtonShowMore from './ButtonShowMore.vue'
  export default {
    components: { ButtonShowMore },
    name: 'LastNews',
    created() {
      this.getNews()
    },
    data() {
      return { principalNew: {}, news: [], loading: null }
    },
    props: {
      title: String,
      button: String,
    },
    methods: {
      async getNews() {
        this.loading = true
        axios
          .get('publications?order=date&by=desc&type=news&active=1&limit=5')
          .then((response) => {
            this.news = response.data
            this.principalNew = response.data[0]
            this.loading = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .principal-new-container {
    height: fit-content;
    .principal-new-img {
      filter: brightness(40%);
    }
    .principal-new-content {
      opacity: 1;
    }
  }
  .items__container {
    min-height: 220px;
    // max-height: 300px;
    // margin: 20px 0;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
    // border-radius: 5px;
    transition: transform 0.3s;
    background-color: #fff;
  }
  .items__container:hover {
    transform: translate3d(6px, 0, 0);
  }
  .item__img-container {
    height: 200px !important;
    display: flex;
    justify-content: center;
  }
  .item__img {
    // object-fit: contain !important;
    width: 100%;
    height: 100%;
    // min-height: 100px !important;
    // align-self: center;
  }
</style>
